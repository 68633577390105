import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useLocation } from "@reach/router";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from 'clsx';
import { Grid } from "@material-ui/core";
import * as styles from './blog.module.css';
import GlobalLayout from '../layouts/globalLayout';

const contentfulQuery = graphql`{
    pictureHeader: file(relativePath: {eq: "blog/glassPsychadelic.JPG"}) {
        childImageSharp {
            gatsbyImageData
        }
    },
    allContentfulBlogPost {
        edges {
            node {
                slug
                title
                shortDescription
                thumbnailImage {
                    gatsbyImageData(height: 400, cropFocus: CENTER)
                    title
                }
                contentful_id
                category {
                    title
                    slug
                }
            }
        }
    }
}`;

export default function Blog() {
    const blogData = useStaticQuery(contentfulQuery);
    const url = useLocation();

    const listOfCategories = blogData.allContentfulBlogPost.edges.reduce((accumCategories, currentBlog) => {
        if (currentBlog.node && currentBlog.node.category && currentBlog.node.category.length) {
            currentBlog.node.category.forEach((category) => {
                if (!accumCategories.find(val => val.slug === category.slug)) {
                    accumCategories.push(category);
                }
            });
        }

        return accumCategories;
    }, []);

    return (
        <GlobalLayout bodyBackgroundColor='rgba(153, 38, 29, 0.3)'>
            <div className={styles.pictureHeaderContainer}>
                <GatsbyImage image={blogData.pictureHeader.childImageSharp.gatsbyImageData} className={styles.pictureHeader} />
                <div className={styles.pictureHeaderTextContainer}>
                    <div className={styles.pictureHeaderText}>
                        blog
                    </div>
                    <div className={styles.pictureHeaderSubText}>
                        BE A FLY ON THE WALL INSIDE THE LOVE SHACK
                    </div>
                </div>
            </div>
            <div className={styles.blogPostContainer}>
                <h1 className={styles.categoryTitle}>
                    CATEGORIES
                </h1>
                <ul className={styles.categoryLinkContainer}>
                    <li className={styles.categoryLink}>
                        <a className={clsx(styles.categoryRef, {
                                [styles.categoryLinkActive]: url.hash === ''
                            })} href={`#`}>
                            All
                        </a>
                    </li>
                    {listOfCategories.length > 0 && <span>•</span>}
                    {listOfCategories && listOfCategories.map((category, index) => (
                        <>
                            <li className={styles.categoryLink}><a className={clsx(styles.categoryRef, {
                                [styles.categoryLinkActive]: url.hash.includes(category.slug)
                            })} href={`#${category.slug}`}>{category.title}</a></li>
                            {index !== listOfCategories.length - 1 && <span>•</span>}
                        </>
                    ))}
                </ul>
                <Grid container className={styles.blogsContainer}>
                    {
                        blogData.allContentfulBlogPost.edges.reduce((accum, blog) => {
                            if (url.hash === '' || (blog.node && blog.node.category && blog.node.category.find(cat => url.hash.includes(cat.slug)))) {
                                accum.push(
                                    <Grid item xs={12} sm={6} className={styles.blogSummary} key={blog.node.slug}>
                                        <div className={styles.blogSummaryContainer}>
                                            <GatsbyImage className={styles.blogSummaryPic} image={blog.node.thumbnailImage.gatsbyImageData} />
                                            <div className={styles.blogSummaryCategory}>
                                                {`category: ${blog.node.category ? blog.node.category.map((val, index) => `${index > 0 ? ' •' : ''}${val.title}`) : 'none'}`}
                                            </div>
                                            <h4 className={styles.blogSummaryTitle}>{blog.node.title}</h4>
                                            <div className={styles.blogSummaryDesc}>
                                                {blog.node.shortDescription}
                                            </div>
                                            <Link to={`/blog/${blog.node.slug}`} className={styles.blogSummaryReadMore}>{`READ MORE . . .`}</Link>
                                        </div>
                                    </Grid>
                                );
                            }

                            return accum;
                        }, [])}
                </Grid>
            </div>
        </GlobalLayout>
    )
}
// extracted by mini-css-extract-plugin
export var pictureHeaderContainer = "blog-module--pictureHeaderContainer--1nUdK";
export var pictureHeader = "blog-module--pictureHeader--3HgFW";
export var pictureHeaderTextContainer = "blog-module--pictureHeaderTextContainer--3uvUF";
export var pictureHeaderText = "blog-module--pictureHeaderText--1rqMy";
export var pictureHeaderSubText = "blog-module--pictureHeaderSubText--KIyZi";
export var blogPostContainer = "blog-module--blogPostContainer--2sJvP";
export var categoryTitle = "blog-module--categoryTitle--3pyeO";
export var categoryLinkContainer = "blog-module--categoryLinkContainer--1c65C";
export var categoryLink = "blog-module--categoryLink--1Hynw";
export var categoryLinkActive = "blog-module--categoryLinkActive--1rug-";
export var categoryRef = "blog-module--categoryRef--ry5Db";
export var blogSummary = "blog-module--blogSummary--3jhlw";
export var blogSummaryContainer = "blog-module--blogSummaryContainer--1txtn";
export var blogSummaryPic = "blog-module--blogSummaryPic--2QNAx";
export var blogSummaryCategory = "blog-module--blogSummaryCategory--2-5_P";
export var blogSummaryTitle = "blog-module--blogSummaryTitle--24hT2";
export var blogSummaryDesc = "blog-module--blogSummaryDesc--1ZAYa";
export var blogSummaryReadMore = "blog-module--blogSummaryReadMore--2dzmS";